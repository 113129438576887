import {
  area,
  bbox,
  bearing,
  center,
  Coord,
  coordAll,
  feature,
  featureCollection,
  getCoord,
  getCoords,
  intersect,
  rhumbBearing,
  rhumbDestination,
  rhumbDistance,
  round,
  transformRotate,
  transformScale,
  transformTranslate
} from '@turf/turf';
import { Position } from 'geojson';
import { Map, LatLngBounds, GeoJSON, LatLng, Point } from 'leaflet';

import { FloorPlanCollectionResponse } from '~/lib/graphql/queries/floorPlan';

interface FilterFloorPlansByBoundsArgs {
  mapBounds: GeoJSON.Polygon;
  hiddenFloorPlanIds?: string[];
  floorPlans?: FloorPlanCollectionResponse['floorPlanCollection'];
}

interface FilterFloorPlansBySizeArgs {
  mapBounds: GeoJSON.Polygon;
  floorPlans?: FloorPlanCollectionResponse['floorPlanCollection'];
}

interface FilterFloorPlansByLevelArgs {
  currentLevel: number | null | undefined;
  floorPlans?: FloorPlanCollectionResponse['floorPlanCollection'];
}

/**
 * Filters floor plans based on the map bounds and hidden floor plan IDs.
 *
 * @param args - The arguments for filtering floor plans by bounds.
 * @param args.mapBounds - The current map bounds as a GeoJSON Polygon.
 * @param args.hiddenFloorPlanIds - An optional array of floor plan IDs to hide.
 * @param args.floorPlans - The floor plans to filter.
 * @returns The filtered floor plans.
 */
export function filterFloorPlansByBounds({
  mapBounds,
  hiddenFloorPlanIds,
  floorPlans
}: FilterFloorPlansByBoundsArgs): FloorPlanCollectionResponse['floorPlanCollection'] {
  return (
    floorPlans?.filter(
      (floorPlan) =>
        !hiddenFloorPlanIds?.includes(floorPlan.id) &&
        intersect(
          featureCollection([feature(floorPlan.geo), feature(mapBounds)])
        )
    ) ?? []
  );
}

/**
 * Filters out floor plans that are less than 0.1% of the map bounds area.
 */
export function filterFloorPlansBySize({
  mapBounds,
  floorPlans
}: FilterFloorPlansBySizeArgs): FloorPlanCollectionResponse['floorPlanCollection'] {
  // Calculate the area of the map bounds
  const mapBoundsArea = area(mapBounds);

  return (
    floorPlans?.filter((floorPlan) => {
      // Calculate the area of the floor plan
      const floorPlanArea = area(floorPlan.geo);

      // Calculate the ratio of the floor plan area to the map bounds area
      const ratio = floorPlanArea / mapBoundsArea;

      // Filter out floor plans that are less than 0.1% of the map area
      return ratio > 0.001;
    }) ?? []
  );
}

/**
 * Filters floor plans by current level.
 * @param args - The arguments for filtering floor plans by level.
 * @param args.currentLevel - The current level being viewed.
 * @param args.floorPlans - The floor plans to filter.
 * @returns The filtered floor plans.
 */
export function filterFloorPlansByLevel({
  currentLevel,
  floorPlans
}: FilterFloorPlansByLevelArgs): FloorPlanCollectionResponse['floorPlanCollection'] {
  return (
    floorPlans?.filter(
      (floorPlan) =>
        currentLevel === undefined ||
        currentLevel === null ||
        floorPlan.level === currentLevel
    ) ?? []
  );
}

/**
 * Sorts floor plans by level (Ascending order, nulls last)
 * Stacking on the map renders last on top.
 * @param floorPlans - The floor plans to sort.
 * @returns The sorted floor plans.
 */
export function sortFloorPlansByLevel(
  floorPlans: FloorPlanCollectionResponse['floorPlanCollection']
): FloorPlanCollectionResponse['floorPlanCollection'] {
  return floorPlans.sort((a, b) => {
    // bubble null levels to the end
    if (a.level === null) return 1;
    if (b.level === null) return -1;
    return a.level - b.level; // ascending order
  });
}

/**
 * Calculate the aspect ratio of a GeoJSON Polygon.
 *
 * Computes the width-to-height ratio of the given rectangular polygon using the
 * rhumb distance between its corners. The rhumb distance calculation accounts
 * for the Earth's curvature, making this function suitable for rectangular
 * polygons of various sizes and locations on the globe.
 *
 * The result is rounded to two decimal places for precision and readability.
 *
 * @param geo - The GeoJSON Polygon for which to calculate the aspect ratio.
 *              Can be null, in which case the function returns null.
 *
 * @returns The aspect ratio of the polygon as a number rounded to two decimal places,
 *          or null if the input is null or if the polygon has zero width or height.
 *          The aspect ratio is always positive, with:
 *          - values > 1 indicating a wider-than-tall shape
 *          - values < 1 indicating a taller-than-wide shape
 *          - a value of 1 indicating a square shape
 *
 * @throws Logs an error to the console if the polygon has zero width or height.
 *
 * Note: This uses rhumb distance calculations for consistency with other
 * geometric operations in the application and to account for the Earth's curvature.
 *
 * @see https://turfjs.org/docs/api/rhumbDistance
 * @see https://turfjs.org/docs/api/getCoords
 * @see https://turfjs.org/docs/api/round
 */
export function rhumbAspectRatio(geo: GeoJSON.Polygon): number | null {
  // Get the coordinates of the polygon
  const [topLeft, topRight, _, bottomLeft] = coordAll(geo) as Position[];

  // Calculate width and height using rhumbDistance
  const width = rhumbDistance(topLeft, topRight, { units: 'meters' });
  const height = rhumbDistance(topLeft, bottomLeft, { units: 'meters' });

  // Check if width or height is zero to avoid division by zero error
  if (width === 0 || height === 0) {
    return null;
  }

  // Calculate and return the aspect ratio
  return round(width / height, 2);
}

/**
 * Returns the bounds of the floor plan based on its corners.
 * @returns - The bounds of the floor plan.
 * @see https://leafletjs.com/reference.html#latlngbounds
 * @see https://turfjs.org/docs/api/bbox
 */
export const boundingBoxLatLng: (geo: GeoJSON.Polygon) => LatLngBounds = (
  geo
) => {
  const boundingBox = bbox(geo);
  return new LatLngBounds(
    [boundingBox[1], boundingBox[0]],
    [boundingBox[3], boundingBox[2]]
  );
};

/**
 * Calculates the orientation of a rectangular polygon relative to the east.
 * @param geo - The floor plan geometry.
 * @returns - The angle in degrees from the east.
 * @see https://turfjs.org/docs/api/bearing
 * @see https://turfjs.org/docs/api/coordAll
 */
export function bearingFromEast(geo: GeoJSON.Polygon): number {
  const [topLeft, topRight] = coordAll(geo) as Position[];
  const bearingFromNorth = bearing(topLeft, topRight);
  // Adjust the bearing to be relative to the east (positive clockwise)
  return -(90 - bearingFromNorth + 360) % 360;
}

/**
 * Calculates the center of the floor plan as a LatLng object.
 * @param geo - The floor plan geometry.
 * @returns - The center of the floor plan as a LatLng object.
 * @see https://turfjs.org/docs/api/center
 * @see https://turfjs.org/docs/api/getCoords
 * @see https://leafletjs.com/reference.html#geojson-coordstolatlng
 */
export function centerLatLng(geo: GeoJSON.Polygon): LatLng {
  return GeoJSON.coordsToLatLng(getCoords(center(geo)) as [number, number]);
}

/**
 * Calculates the zoom level to fit the floor plan within the map view.
 * @param geo - The floor plan geometry.
 * @returns - The zoom level to fit the floor plan.
 * @see https://turfjs.org/docs/api/area
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/log
 */
export function zoomToFit(geo: GeoJSON.Polygon) {
  // Calculate the area of the floor plan in square meters
  const geoArea = area(geo);

  // Zoom level calculation constants
  const MIN_ZOOM = 10; // Minimum zoom level (zoomed out)
  const MAX_ZOOM = 21; // Maximum zoom level (zoomed in)
  const MIN_AREA = 10; // 10 sq meters (very small object)
  const MAX_AREA = 1_000_000_000; // 1000 sq km (very large object)

  // Clamp ("normalize") the area between 10 and 1,000,000,000 square meters
  // This avoids extreme values distorting the zoom level calculation
  const normalizedArea = Math.max(MIN_AREA, Math.min(MAX_AREA, geoArea));

  // Compute the natural logarithm of the clamped area. We use logarithms as
  // areas vary drastically: from as low as 10 square meters to as high as
  // 1,000 square kilometers. This is a huge range, and if we tried to scale
  // areas linearly, small areas would get almost no difference in zoom compared
  // to large areas, and large areas would dominate everything. Taking the log
  // compresses large values and expands small values so we have a more balanced
  // scale. In other words, a logarithmic scale keeps the extremes from
  // overshadowing moderate areas.
  const logNormalizedArea = Math.log(normalizedArea);

  // Shift the logarithm so that MIN_AREA starts at 0 on this scale.
  const logNormalizedAreaShifted = logNormalizedArea - Math.log(MIN_AREA);

  // Divide by the range of the logarithm to get a fraction between 0 and 1.
  // In other words, if normalizedArea equals MIN_AREA, the fraction becomes 0.
  // If normalizedArea equals MAX_AREA, the fraction becomes 1.
  // In between, it smoothly varies from 0 to 1.
  const fraction =
    logNormalizedAreaShifted / (Math.log(MAX_AREA) - Math.log(MIN_AREA));

  // Convert the 0-1 fraction to a zoom level between MIN_ZOOM and MAX_ZOOM.
  const zoomLevel = MAX_ZOOM - fraction * (MAX_ZOOM - MIN_ZOOM);

  return Math.round(zoomLevel);
}

/**
 * Extracts the first four coordinates from a GeoJSON Polygon.
 * @param geo - The GeoJSON Polygon to extract coordinates from.
 * @returns An array of the first four coordinates.
 * @see https://turfjs.org/docs/api/coordAll
 */
export function firstFourCoords(geo: GeoJSON.Polygon): Position[] {
  const coordinates = coordAll(geo) as Position[];
  return coordinates.slice(0, 4);
}

/**
 * Converts the first four coordinates of a GeoJSON Polygon to Leaflet LatLng objects.
 * @param geo - The GeoJSON Polygon to convert.
 * @returns An array of Leaflet LatLng objects.
 * @see https://leafletjs.com/reference.html#geojson-coordstolatlngs
 */
export function firstFourLatLngs(geo: GeoJSON.Polygon): LatLng[] {
  return GeoJSON.coordsToLatLngs(firstFourCoords(geo));
}

/**
 * Calculates the base scale factor for a floor plan image.
 *
 * This function determines the scale factor needed to align a floor plan image
 * with its corresponding geographical coordinates on the map at a given base zoom level.
 *
 * The process involves:
 * 1. Projecting the corner coordinates of the floor plan onto the map at the base zoom level.
 * 2. Calculating the projected dimensions of the floor plan on the map.
 * 3. Determining the scale factor between the image size and its map projection.
 *
 * Corner points of the floor plan:
 *     topLeft  -----  topRight
 *        |               |
 *        |               |
 *     bottomLeft      bottomRight
 *
 * @param map - The Leaflet Map instance.
 * @param geo - The GeoJSON Polygon representing the floor plan's geometry.
 * @param baseZoom - The base zoom level for consistent calculations.
 * @param imageWidth - The natural width of the floor plan image.
 * @param imageHeight - The natural height of the floor plan image.
 * @returns The calculated base scale factor.
 *
 * @see https://leafletjs.com/reference.html#map-project
 * @see https://leafletjs.com/reference.html#point-distanceto
 * @see https://turfjs.org/docs/api/coordAll
 * @see https://turfjs.org/docs/api/round
 */
export function baseScaleFactor(
  map: Map,
  geo: GeoJSON.Polygon,
  baseZoom: number,
  imageWidth: number,
  imageHeight: number
): number {
  const [topLeft, topRight, , bottomLeft] = coordAll(geo).map((coord) =>
    map.project(GeoJSON.coordsToLatLng(coord as [number, number]), baseZoom)
  );

  const projectedWidth = topRight.distanceTo(topLeft);
  const projectedHeight = bottomLeft.distanceTo(topLeft);

  // Calculate the base scale factor between the image's natural size and
  // projected size at base zoom
  const scaleFactorX = projectedWidth / imageWidth;
  const scaleFactorY = projectedHeight / imageHeight;
  return (scaleFactorX + scaleFactorY) / 2;
}

type CursorStyle =
  | 'nwResize'
  | 'neResize'
  | 'seResize'
  | 'swResize'
  | 'default';

/**
 * Maps the corners of a GeoJSON Polygon to cursor styles based on their
 * positions relative to the center.
 *
 * @param geo - The GeoJSON Polygon to analyze.
 * @returns An array of cursor styles for each corner.
 * @see https://turfjs.org/docs/api/center
 * @see https://turfjs.org/docs/api/getCoord
 */
export function mapCornersToCursorStyles(geo: GeoJSON.Polygon): CursorStyle[] {
  const corners = firstFourCoords(geo);
  const geoCenter = center(geo);
  const centerCoord = getCoord(geoCenter) as Position;

  function mapCornerToCursorStyle(corner: Position): CursorStyle {
    const [cornerLng, cornerLat] = corner;
    const [centerLng, centerLat] = centerCoord;

    const isAbove = cornerLat > centerLat;
    const isBelow = cornerLat < centerLat;
    const isLeft = cornerLng < centerLng;
    const isRight = cornerLng > centerLng;

    if (isAbove && isLeft) return 'nwResize';
    if (isAbove && isRight) return 'neResize';
    if (isBelow && isRight) return 'seResize';
    if (isBelow && isLeft) return 'swResize';

    return 'default';
  }

  // Assign cursor styles based on quadrant of the polygon
  return corners.map(mapCornerToCursorStyle);
}

/**
 * Scales a rectangular floor plan geometry based on corner dragging.
 *
 * This function calculates how much a floor plan geometry should be scaled when one of its corners
 * is dragged, using the opposite corner as a fixed point. It computes the ratio of
 * the new distance (after dragging) to the original distance between the corners,
 * and then applies this scale factor to the entire geometry.
 *
 * @param geo - The original GeoJSON Polygon representing the floor plan
 * @param oppositeCorner - The fixed corner coordinates (longitude, latitude) opposite to the dragged corner
 * @param draggedCorner - The original position (longitude, latitude) of the corner being dragged
 * @param dragEnd - The new position (longitude, latitude) where the dragged corner was released
 * @returns A new GeoJSON Polygon representing the scaled floor plan geometry
 *
 * @see https://turfjs.org/docs/api/rhumbDistance
 * @see https://turfjs.org/docs/api/round
 * @see https://turfjs.org/docs/api/transformScale
 *
 * @example
 * const scaledGeo = dragScaleFactor(originalGeo, [0, 0], [1, 1], [2, 2]);
 * // Returns a new GeoJSON Polygon scaled by approximately 1.41 (√2)
 */
export function dragScaleGeo(
  geo: GeoJSON.Polygon,
  oppositeCorner: Position,
  draggedCorner: Position,
  dragEnd: Position
): GeoJSON.Polygon {
  const options = { units: 'meters' } as const;

  // Calculate the previous distance between the active corner's marker and the scale origin
  const previousDistance = rhumbDistance(
    oppositeCorner,
    draggedCorner,
    options
  );

  // Calculate the new distance between the target corner's marker and the scale origin
  const targetDistance = rhumbDistance(oppositeCorner, dragEnd, options);

  // Calculate the scale factor based on the ratio of the new distance to the previous distance
  const scaleFactor = targetDistance / previousDistance;

  // Scale the geo based on the factor
  const scaledGeo = transformScale(geo, scaleFactor, {
    origin: oppositeCorner
  });

  return scaledGeo;
}

/**
 * Calculates the rotation angle and applies it to the floor plan geometry based on a target coordinate.
 *
 * This function determines the angle by which a floor plan geometry should be rotated
 * when a rotation handle is dragged to a new position. It calculates the difference
 * between the new bearing (based on the target coordinate) and the current upwards
 * bearing of the geometry. Then, it applies this rotation to the geometry.
 *
 * @param geo - The current floor plan geometry (GeoJSON Polygon)
 * @param targetCoord - The coordinate where the rotation handle was dragged to
 * @returns The rotated GeoJSON Polygon
 *
 * @see https://turfjs.org/docs/api/bearing
 * @see https://turfjs.org/docs/api/center
 * @see https://turfjs.org/docs/api/coordAll
 * @see https://turfjs.org/docs/api/transformRotate
 *
 * @example
 * const rotatedGeo = dragRotationAngle(floorPlanGeo, [lon, lat]);
 * // Returns the rotated floor plan geometry
 */
export function dragRotateGeo(
  geo: GeoJSON.Polygon,
  targetCoord: Coord
): GeoJSON.Polygon {
  // Calculate the current upwards bearing of the geometry
  const [, topRight, bottomRight] = coordAll(geo) as Position[];
  const bearingToTop = rhumbBearing(bottomRight, topRight);

  // Get the center of the geometry
  const geoCenter = center(geo);

  // Calculate the new bearing based on the marker's position
  const newBearing = rhumbBearing(geoCenter, targetCoord);

  // Calculate the rotation angle
  let rotationAngle = newBearing - bearingToTop;

  // Normalize the rotation angle to be between -180 and 180 degrees
  rotationAngle = ((rotationAngle + 180) % 360) - 180;

  // Rotate the geometry
  const newGeo = transformRotate(geo, rotationAngle);

  return newGeo;
}

/**
 * Translates a GeoJSON Polygon based on the movement of a drag operation.
 *
 * This function calculates the distance and bearing between the initial mouse
 * coordinates and the current mouse coordinates, then applies this translation
 * to the entire GeoJSON Polygon.
 *
 * @param geo - The original GeoJSON Polygon to be translated
 * @param initialMouseCoords - The starting coordinates of the drag operation [longitude, latitude]
 * @param currentMouseCoords - The current coordinates of the mouse during drag [longitude, latitude]
 * @returns A new GeoJSON Polygon representing the translated floor plan geometry
 *
 * @see https://turfjs.org/docs/api/rhumbDistance
 * @see https://turfjs.org/docs/api/rhumbBearing
 * @see https://turfjs.org/docs/api/transformTranslate
 *
 * @example
 * const translatedGeo = dragTranslateGeo(originalGeo, [0, 0], [1, 1]);
 * // Returns a new GeoJSON Polygon translated based on the movement from [0, 0] to [1, 1]
 */
export function dragTranslateGeo(
  geo: GeoJSON.Polygon,
  initialMouseCoords: Position,
  currentMouseCoords: Position
): GeoJSON.Polygon {
  const options = { units: 'meters' } as const;
  const distanceToCursor = rhumbDistance(
    initialMouseCoords,
    currentMouseCoords,
    options
  );
  const bearingToCursor = rhumbBearing(initialMouseCoords, currentMouseCoords);
  const translatedGeo = transformTranslate(
    geo,
    distanceToCursor,
    bearingToCursor,
    options
  );

  return translatedGeo;
}

/**
 * Calculates the position of the rotation handle for a given floor plan geometry.
 *
 * This function determines the location of a rotation handle above the floor plan,
 * positioned at a distance of 1/1.6 times the height of the floor plan from its center.
 * The handle is aligned with the upward direction of the floor plan.
 *
 * @param geo - The GeoJSON Polygon representing the floor plan geometry.
 * @returns A LatLng object representing the position of the rotation handle.
 *
 * @see https://turfjs.org/docs/api/coordAll
 * @see https://turfjs.org/docs/api/center
 * @see https://turfjs.org/docs/api/rhumbDistance
 * @see https://turfjs.org/docs/api/rhumbBearing
 * @see https://turfjs.org/docs/api/rhumbDestination
 * @see https://leafletjs.com/reference.html#geojson-coordstolatlng
 */
export function rotationHandleLatLng(geo: GeoJSON.Polygon): LatLng {
  // Get the coordinates of the rectangle corners needed to calculate the handle position
  const [, topRight, bottomRight] = coordAll(geo);

  // Get the center of the geometry
  const geoCenter = center(geo);

  // Calculate height of the rectangle
  const height = rhumbDistance(topRight, bottomRight, {
    units: 'meters'
  });

  // Use a fraction of the height to determine the distance of the handle
  const handleDistance = height / 1.6;

  // Calculate the bearing (angle) of the rectangle
  const bearingToTop = rhumbBearing(bottomRight, topRight);

  // Calculate the destination point for the handle
  const handleCoord = getCoord(
    rhumbDestination(geoCenter, handleDistance, bearingToTop, {
      units: 'meters'
    })
  ) as [number, number];

  return GeoJSON.coordsToLatLng(handleCoord);
}

/**
 * Sets the transform CSS property on the image element so that it is translated by `offset` pixels,
 * optionally scaled by `scale`, and rotated by `angle` degrees around the Z-axis. Uses 3D transforms to force GPU
 * acceleration.
 * @param img - The HTMLImageElement to transform
 * @param offset - The offset in pixels
 * @param scale - The scale factor
 * @param angle - The rotation angle in degrees
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/translate3d
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/rotate3d
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/scale3d
 */
export function setImageTransform(
  img: HTMLImageElement,
  offset: Point,
  scale?: number,
  angle?: number
): void {
  const position = offset || new Point(0, 0);
  const scaleStr = scale ? ` scale3d(${scale}, ${scale}, ${scale})` : '';
  const angleDeg = angle || 0;
  const rotateStr = angleDeg
    ? ` rotate3d(0, 0, 1, ${angleDeg.toFixed(2)}deg)`
    : '';

  img.style.transform = `
        translate3d(${position.x.toFixed(0)}px, ${position.y.toFixed(0)}px, 0)
        translate3d(-50%, -50%, 0)
        ${rotateStr}
        ${scaleStr}
      `;
}
