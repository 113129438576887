import {
  createElementHook,
  createElementObject,
  createLayerHook,
  LayerProps,
  LeafletContextInterface,
  useEventHandlers,
  useLayerLifecycle,
  useLeafletContext
} from '@react-leaflet/core';
import { coordReduce } from '@turf/turf';
import {
  DomUtil,
  LatLng,
  LatLngBounds,
  Layer,
  LeafletEvent,
  Map,
  Point,
  ZoomAnimEvent,
  GeoJSON
} from 'leaflet';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useMapEvents } from 'react-leaflet';

import {
  baseScaleFactor,
  bearingFromEast,
  boundingBoxLatLng,
  centerLatLng,
  setImageTransform
} from '../../floor-plan-fns';

interface FloorPlanOverlayProps extends LayerProps {
  url: string;
  geo: GeoJSON.Polygon;
  editing?: boolean;
  zIndex?: number;
}

/**
 *
 * @see https://leafletjs.com/reference.html
 * @see https://github.com/Leaflet/Leaflet/blob/main/src/dom/DomUtil.js
 * @see https://github.com/Leaflet/Leaflet/blob/main/src/geometry/Bounds.js
 * @see https://github.com/Leaflet/Leaflet/blob/main/src/layer/Layer.js
 * @see https://github.com/Leaflet/Leaflet/blob/main/src/layer/ImageOverlay.js
 */
class FloorPlanLayer extends Layer {
  private _img: HTMLImageElement;
  private _url: string;
  private _zIndex: number;

  constructor(url: string, options: FloorPlanOverlayProps) {
    super(options);
    this._img = FloorPlanLayer.createImg(url);
    this._url = url;
    this._zIndex = options.zIndex ?? 400; // 400 is the default in Leaflet CSS .leaflet-overlay-pane
    this._onImgAdd();
    this.setEditing(options.editing ?? false);
  }

  /**
   * Creates and configures an HTMLImageElement for use as a floor plan overlay.
   *
   * This method sets up the image element with appropriate CSS classes and styles
   * to ensure proper rendering within the Leaflet map. It prepares the image for
   * GPU-accelerated transforms and positions it absolutely within its container.
   *
   * @param src - The source URL of the image to be loaded.
   * @returns A configured HTMLImageElement ready to be used as a floor plan overlay.
   *
   * @see https://leafletjs.com/reference.html#domutil-create
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/position
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/will-change
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin
   */
  private static createImg(src: string): HTMLImageElement {
    const img = DomUtil.create('img', 'leaflet-image-layer');
    img.setAttribute('src', src);
    img.classList.add('leaflet-zoom-animated');
    img.style.position = 'absolute';
    img.style.willChange = 'transform';
    img.style.transformOrigin = 'center center';
    return img;
  }

  onAdd(_map: Map) {
    const pane = this.getPane();
    if (pane) {
      pane.appendChild(this._img);
      this._img.style.zIndex = this._zIndex.toString();
    }
    return this;
  }

  onRemove(_map: Map) {
    this._img.onload = null;
    this._img.onerror = null;
    this.getPane()?.removeChild(this._img);
    return this;
  }

  getImg(): HTMLImageElement {
    return this._img;
  }

  getUrl(): string {
    return this._url;
  }

  setUrl(url: string) {
    this._url = url;
    this._img.setAttribute('src', url);
  }

  setEditing(editing: boolean) {
    if (editing) {
      this._img.style.opacity = '0.7';
    } else {
      this._img.style.opacity = '1';
    }
  }

  /**
   * Sets up event handlers for the floor plan image.
   *
   * This private method attaches 'onload' and 'onerror' event handlers to the image element.
   * - The 'onload' handler fires a 'load' event when the image successfully loads.
   * - The 'onerror' handler fires an 'error' event with detailed information when the image fails to load.
   *
   * These events can be listened to externally to respond to successful image loading or loading failures.
   *
   * @private
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/load_event
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/error_event
   * @see https://leafletjs.com/reference.html#evented-fire
   */
  private _onImgAdd() {
    this._img.onload = this.fire.bind(this, 'load');
    this._img.onerror = (_event: Event | string) => {
      const leafletEvent: LeafletEvent = {
        layer: this,
        popup: null,
        propagatedFrom: this,
        sourceTarget: this,
        target: this,
        type: 'error'
      };
      this.fire('error', leafletEvent);
    };
  }
}

const createFloorPlanOverlay = (
  props: FloorPlanOverlayProps,
  context: LeafletContextInterface
) => {
  const { url } = props;

  const instance = new FloorPlanLayer(url, props);

  return createElementObject(instance, context);
};

const updateFloorPlanOverlay = (
  instance: FloorPlanLayer,
  props: FloorPlanOverlayProps,
  prevProps: FloorPlanOverlayProps
) => {
  if (props.url !== prevProps.url) {
    instance.setUrl(props.url);
  }
  if (props.editing !== prevProps.editing) {
    instance.setEditing(props.editing || false);
  }
};

const useFloorPlanElement = createElementHook(
  createFloorPlanOverlay,
  updateFloorPlanOverlay
);

const useFloorPlanLayer = createLayerHook(useFloorPlanElement);

interface FloorPlanOverlayState {
  centerLatLng: LatLng;
  angleInDegrees: number;
  baseScaleFactor: number;
  baseZoom: number;
  bounds: LatLngBounds;
}

export const FloorPlanOverlay: FC<FloorPlanOverlayProps> = (
  props: FloorPlanOverlayProps
) => {
  const context = useLeafletContext();
  const { map } = context;
  const { geo } = props;

  // Mutable ref to hold the layer instance
  const layerRef = useFloorPlanLayer(props);

  // Mutable ref to hold state without causing re-renders
  const stateRef = useRef<FloorPlanOverlayState>({
    angleInDegrees: bearingFromEast(geo),
    baseScaleFactor: 1,
    baseZoom: 0,
    bounds: boundingBoxLatLng(geo),
    centerLatLng: centerLatLng(geo)
  });

  // State to track if the floor plan image failed to load
  const [hasError, setHasError] = useState(false);

  /**
   * Sets up base scale and zoom values for scaling calculations.
   **/
  const initBaseScaleFactor = useCallback(() => {
    const { instance } = layerRef.current;
    const state = stateRef.current;

    // Choose a static zoom level with high precision, e.g., map's max zoom level minus 4
    state.baseZoom = map.getMaxZoom() - 4;

    const img = instance.getImg();

    // Calculate the base scale factor
    state.baseScaleFactor = baseScaleFactor(
      map,
      geo,
      state.baseZoom,
      img.naturalWidth,
      img.naturalHeight
    );
  }, [geo, layerRef, map, stateRef]);

  const setErrorImage = useCallback(() => {
    const { instance } = layerRef.current;

    // Convert geo to pixel points
    const points = coordReduce(
      geo,
      (acc: Point[], coord: number[]) => {
        return [
          ...acc,
          map.latLngToLayerPoint(
            GeoJSON.coordsToLatLng(coord as [number, number])
          )
        ];
      },
      []
    );

    // Calculate width and height in pixels
    const width = points[1].distanceTo(points[0]);
    const height = points[3].distanceTo(points[0]);

    // Create an SVG image with a red background and a cross icon to match blueprint's error callout
    // TODO: Replace inline SVG with props for error svg, or refactor to be able to pass in a JSX component (not an easy task)
    const svg = `data:image/svg+xml;base64,${btoa(`
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
        <rect width="100%" height="100%" fill="#cd4246" opacity="0.1" />
        <g transform="translate(${width / 2 - 100}, ${height / 2 - 10})">
          <path fill="#ac2f33" d="M16 0C7.16 0 0 7.16 0 16s7.16 16 16 16 16-7.16 16-16S24.84 0 16 0zm1.5 24h-3V21h3v3zm0-5h-3V8h3v11z" />
          <text x="48" y="16" font-family="-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,blueprint-icons-16,sans-serif" font-size="13" fill="#ac2f33" alignment-baseline="middle">Floor Plan Error</text>
        </g>
      </svg>
    `)}`;

    instance.setUrl(svg); // Set the SVG URL
  }, [geo, layerRef, map]);

  /**
   * Resets and updates the floor plan overlay's position, size, and visibility whenever the map's view changes
   * (e.g., zooming, panning).
   */
  const reset = useCallback(() => {
    const { instance } = layerRef.current;
    const img = instance.getImg();
    const state = stateRef.current;

    // Get the image's natural dimensions
    const imageNaturalWidth = img.naturalWidth;
    const imageNaturalHeight = img.naturalHeight;

    // Set the image's dimensions to its natural size
    img.style.width = `${imageNaturalWidth}px`;
    img.style.height = `${imageNaturalHeight}px`;

    // Compute the current scale factor relative to the base zoom level
    const currentZoom = map.getZoom();
    const currentZoomScale = map.getZoomScale(currentZoom, state.baseZoom);
    const scaleFactor = state.baseScaleFactor * currentZoomScale;

    // Get the position of the center point at the current zoom level
    const centerPoint = map.latLngToLayerPoint(state.centerLatLng);

    // Apply the transformations
    setImageTransform(img, centerPoint, scaleFactor, state.angleInDegrees);

    // Adjust the visibility of the image based on the map's bounds
    if (!map.getBounds().intersects(state.bounds)) {
      img.style.display = 'none';
    } else {
      img.style.display = '';
    }
  }, [layerRef, map]);

  /**
   * Animates the floor plan overlay's scale and position during zoom animations.
   * @param e - The event object containing information about the zoom animation.
   * @see https://leafletjs.com/reference.html#map-zoomanim
   * @see https://leafletjs.com/reference.html#map-latlngtolayerpoint
   * @see https://leafletjs.com/reference.html#map-getzoomscale
   */
  const onZoomAnim = useCallback(
    (e: ZoomAnimEvent) => {
      const { instance } = layerRef.current;
      const state = stateRef.current;

      // Compute the scale factor for the zoom animation relative to the base zoom level
      const zoomScale = map.getZoomScale(e.zoom, state.baseZoom);
      const scaleFactor = state.baseScaleFactor * zoomScale;

      // Compute the position of the center point at the target zoom level
      const centerPoint = map
        // @ts-expect-error - _latLngToNewLayerPoint is private
        ._latLngToNewLayerPoint(state.centerLatLng, e.zoom, e.center)
        .round();

      const img = instance.getImg();

      // Apply the transformations
      setImageTransform(img, centerPoint, scaleFactor, state.angleInDegrees);
    },
    [layerRef, map]
  );

  const onZoomEnd = useCallback(() => {
    reset();
    if (hasError) {
      setErrorImage();
    }
  }, [hasError, reset, setErrorImage]);

  /**
   * Updates the floor plan overlay's state whenever the geometry changes.
   */
  useEffect(() => {
    const state = stateRef.current;

    // Update the center point based on the new geometry
    state.centerLatLng = centerLatLng(geo);

    // Update the east bearing (positive clockwise) based on the new geometry
    state.angleInDegrees = bearingFromEast(geo);

    // Update the bounding box based on the new geometry
    state.bounds = boundingBoxLatLng(geo);

    // Reset the base scale factor
    initBaseScaleFactor();

    // Reset the floor plan transformation
    reset();
  }, [geo, initBaseScaleFactor, reset, stateRef]);

  /**
   * Initializes the floor plan overlay when the image is loaded.
   */
  const onLoad = useCallback(() => {
    // Initialize the base scale factor
    initBaseScaleFactor();

    // Initialize the floor plan transformation
    reset();
  }, [initBaseScaleFactor, reset]);

  /**
   * Handles errors when loading the floor plan image.
   */
  const onError = useCallback(
    (_e: LeafletEvent) => {
      setHasError(true);
      setErrorImage();
    },
    [setErrorImage, setHasError]
  );

  useEventHandlers(layerRef.current, {
    error: onError,
    load: onLoad
  });

  /**
   * Map event handlers
   * @see https://leafletjs.com/reference.html#map-move
   * @see https://leafletjs.com/reference.html#map-viewreset
   * @see https://leafletjs.com/reference.html#map-zoomanim
   * @see https://leafletjs.com/reference.html#map-zoomend
   */
  useMapEvents({
    move: reset,
    viewreset: reset,
    zoomanim: onZoomAnim,
    zoomend: onZoomEnd
  });

  useLayerLifecycle(layerRef.current, context);

  return null;
};
