import { Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { LatLngLiteral, Map as LeafletMap } from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { useState, FC, ReactNode, Fragment } from 'react';
import {
  AttributionControl,
  MapContainer,
  LayersControl,
  Polyline
} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import { FloorPlanCollectionResponse } from '~/lib/graphql/queries/floorPlan';
import { useDebug } from '~/lib/hooks/debug';

import { useLocalisation } from '../../../lib/hooks/localisation';
import { JsonContentElementProps } from '../../organisms/JsonContent/JsonContent';

import { ClusterWrapper } from './Cluster/ClusterWrapper';
import { LevelsControl } from './Controls/Levels/LevelsControl';
import { Heatmap } from './Heatmap';
import { JsonLayers } from './JsonLayers/JsonLayers';
import FloorPlanLayerGroup from './Layers/FloorPlanLayerGroup/FloorPlanLayerGroup';
import styles from './Map.module.scss';
import { MapDimensions, MapDimensionsUpdater } from './MapDimensionsUpdater';
import { MapPlaceholder } from './MapPlaceholder';
import { Marker as MarkerEl } from './Marker/Marker';
import { MarkerWrapper, MarkerWrapperOptions } from './Marker/MarkerWrapper';
import type { Bounds, Cluster, HeatmapLocation, Marker } from './types';
import { SetMapOptions } from './utils/SetMapOptions';

export interface MapBaseOptions {
  currentLevel?: number | null;
  onDimensionsChanged?: (dimensions: MapDimensions) => void;
}

interface MapBoundsOptions {
  bounds: Bounds;
}

interface MapCenterOptions {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  zoomOffset?: number;
}

export type MapOptions = MapBaseOptions & (MapBoundsOptions | MapCenterOptions);

export interface MapProps {
  children?: ReactNode;
  className?: string;
  clusters?: Cluster[];
  heatmap?: HeatmapLocation[];
  loading?: boolean;
  levels?: number[];
  floorPlans?: FloorPlanCollectionResponse['floorPlanCollection'];
  currentLevel?: number | null;
  onLevelChange?: (level: number | null) => void;
  markers?: Marker[];
  markersOptions?: MarkerWrapperOptions;
  marker?: LatLngLiteral;
  options: MapOptions;
}

// Initialise the gestureHandling plugin
LeafletMap.addInitHook('addHandler', 'gestureHandling', GestureHandling);

/**
 * Map component
 */
const Map: FC<MapProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const debug = useDebug();

  const localisation = useLocalisation();
  const localisedLayers = localisation.getDecoded<JsonContentElementProps>(
    'ui.global.map.layers'
  );

  const googleApiKey = localisation.get('ui.global.map.googleApiKey');

  const antemeridianCoords: [number, number][] = [
    [90, 180], // North Pole at 180°
    [-90, 180] // South Pole at 180°
  ];

  return (
    <div className={[styles.map, props.className].join(' ').trim()}>
      <MapContainer
        attributionControl={false}
        className={styles.container}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gestureHandling={true}
        placeholder={<MapPlaceholder />}
        scrollWheelZoom={true}
        maxZoom={21}
        style={{ zIndex: 0 }}
        whenReady={() => setLoaded(true)}
      >
        {debug && (
          <Polyline
            positions={antemeridianCoords}
            color="red"
            weight={2}
            opacity={0.7}
          />
        )}
        <AttributionControl position="bottomright" prefix="" />
        <MapDimensionsUpdater
          currentLevel={props.currentLevel}
          onDimensionsChanged={props.options.onDimensionsChanged}
        />
        <SetMapOptions
          loaded={loaded}
          marker={props.marker}
          options={props.options}
        />

        {!localisedLayers && (
          <LayersControl collapsed={false} position="topright">
            <LayersControl.BaseLayer checked name="Road">
              <ReactLeafletGoogleLayer
                apiKey={googleApiKey || process.env.MAPS_API_KEY}
                type={'roadmap'}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Hybrid">
              <ReactLeafletGoogleLayer
                apiKey={googleApiKey || process.env.MAPS_API_KEY}
                type={'hybrid'}
              />
            </LayersControl.BaseLayer>
            {props.floorPlans && (
              <FloorPlanLayerGroup floorPlans={props.floorPlans} />
            )}
          </LayersControl>
        )}

        {localisedLayers && (
          <Fragment>
            <JsonLayers content={localisedLayers} />
            <LayersControl collapsed={false} position="topright">
              {props.floorPlans && (
                <FloorPlanLayerGroup floorPlans={props.floorPlans} />
              )}
            </LayersControl>
          </Fragment>
        )}

        <ClusterWrapper clusters={props.clusters} />
        <Heatmap points={props.heatmap} />
        <MarkerWrapper markers={props.markers} options={props.markersOptions} />

        {props.marker && (
          <MarkerEl
            colour={Colors.RED3}
            icon={IconNames.SEARCH}
            position={props.marker}
          />
        )}

        {props.children}

        {props.levels && props.onLevelChange && (
          <LevelsControl
            position="bottomright"
            levels={props.levels}
            currentLevel={props.currentLevel ?? null}
            onChange={props.onLevelChange}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default Map;
