import { FC } from 'react';
import { LayersControl, LayerGroup, useMap } from 'react-leaflet';

import { FloorPlanCollectionResponse } from '~/lib/graphql/queries/floorPlan';

import { FloorPlanOverlay } from '../FloorPlanOverlay/FloorPlanOverlay';

interface FloorPlanLayerGroupProps {
  floorPlans: FloorPlanCollectionResponse['floorPlanCollection'];
}

/**
 * FloorPlanLayerGroup Component
 *
 * This component render floor plans as a layer group on a map. Querying and
 * filtering of floor plans is handled by an ancestor component.
 *
 * Props:
 * - floorPlans: Array of floor plans to render on the map.
 */
const FloorPlanLayerGroup: FC<FloorPlanLayerGroupProps> = ({ floorPlans }) => {
  const map = useMap();

  return (
    <LayersControl.Overlay checked name="Floor Plans">
      <LayerGroup>
        {floorPlans.map((floorPlan, index) => {
          /**
           * @see https://github.com/PaulLeCam/react-leaflet/issues/271 re custom key prop
           *      for correct layer ordering and re-rendering.
           */
          const key = `${index}-${map.getZoom()}-${floorPlan.id}`;
          return (
            <FloorPlanOverlay
              key={key}
              url={floorPlan.url}
              geo={floorPlan.geo}
            />
          );
        })}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

export default FloorPlanLayerGroup;
