import { ApolloClient } from '@apollo/client';

import { GET_ANPR_SITE_MAP } from '../graphql/localQueries/anpr';

/**
 *
 * @param client
 * @param path
 */
export async function fetchSiteMapData(
  client: ApolloClient<any>,
  path: string
) {
  try {
    const res = await fetch(path);

    if (res.status !== 200) return;

    const data = await res.json();

    for (const id of data) {
      client.writeQuery({
        data: { anprSiteMap: { id } },
        query: GET_ANPR_SITE_MAP,
        variables: { id }
      });
    }
  } catch (e) {
    // noop
  }
}
