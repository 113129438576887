import useResizeObserver from '@react-hook/resize-observer';
import { bboxPolygon, getGeom } from '@turf/turf';
import { LatLngBounds } from 'leaflet';
import { useEffect, useCallback, FC } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

import { MapBaseOptions } from './Map';

export interface MapDimensions {
  bounds: LatLngBounds;
  boundsPolygon: GeoJSON.Polygon;
  center: {
    lat: number;
    lng: number;
  };
  currentLevel: number | null;
  zoom: number;
}

type MapDimensionsUpdaterProps = MapBaseOptions;

/**
 * MapDimensionsUpdater Component
 *
 * This component provides real-time map dimension updates to its parent component.
 * It listens to map events (move, resize, zoom) and reports the current map dimensions
 * including bounds, center, and zoom level.
 *
 * @param props - The component props
 * @returns This component doesn't render anything
 */
export const MapDimensionsUpdater: FC<MapDimensionsUpdaterProps> = ({
  currentLevel,
  onDimensionsChanged
}) => {
  const map = useMap();

  const updateDimensions = useCallback(() => {
    if (!onDimensionsChanged) {
      return;
    }

    const bounds = map.getBounds();
    const center = map.getCenter();
    const boundsPolygon = getGeom(
      bboxPolygon([
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth()
      ])
    );

    const dimensions: MapDimensions = {
      bounds,
      boundsPolygon,
      center: {
        lat: center.lat,
        lng: center.lng
      },
      currentLevel: currentLevel ?? null,
      zoom: map.getZoom()
    };

    onDimensionsChanged(dimensions);
  }, [currentLevel, map, onDimensionsChanged]);

  useEffect(() => {
    map.whenReady(updateDimensions);
  }, [map, updateDimensions]);

  // Update dimensions on map events that could change them
  useMapEvents({
    moveend: updateDimensions,
    zoomend: updateDimensions
  });

  // Invalidate the map size when the container resizes
  const container = map.getContainer();

  useResizeObserver(container, () => {
    map.invalidateSize();
  });

  return null;
};
