import { latLngBounds } from 'leaflet';

import type { Point } from './types';

interface MapBounds {
  east: number;
  north: number;
  south: number;
  west: number;
}

/**
 *
 * @param points
 */
export function pointsToBounds(points: Point[]): MapBounds {
  const bounds = latLngBounds(points);

  return {
    east: bounds.getEast(),
    north: bounds.getNorth(),
    south: bounds.getSouth(),
    west: bounds.getWest()
  };
}

/**
 * Processes levels from any array of objects with a level property and additional sources.
 *
 * @param items - An array of objects that contain level information.
 * @param getLevelFn - A function to extract the level from an item.
 * @param additionalLevels - Optional array of additional levels to include.
 * @returns An array of unique, sorted levels in descending order.
 */
export function processLevels<T>(
  items: T[],
  getLevelFn: (item: T) => number | null,
  additionalLevels: number[] = []
): number[] {
  const allLevels = items.reduce<Set<number>>((acc, item) => {
    const level = getLevelFn(item);
    if (level !== null) {
      acc.add(level);
    }
    return acc;
  }, new Set<number>(additionalLevels));

  return Array.from(allLevels)
    .sort((a, b) => a - b)
    .reverse();
}
